// Frontend equivalent of the ruby Money object.
// Can be constructed from the serialized money format.
// NOTE: Currently is pretty simple, but can evolve to support
// different currencies, languages, and localizations.
export default class Money {
  constructor({ amount, currency }) {
    this.amount = amount
    this.currency = currency
  }

  toString(opts) {
    opts ||= {}
    if (!opts.zeroNotFree && this.amount === 0) return 'Free'

    return this.amount.toLocaleString(undefined, {
      style: 'currency',
      currency: this.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  // Return a new Money which is this value / 12
  annualToMonthly() {
    return new Money({ amount: this.amount / 12, currency: this.currency })
  }

  plus(other) {
    if (this.currency !== other.currency) throw new Error('Unimplemented')

    return new Money({
      amount: this.amount + other.amount,
      currency: this.currency,
    })
  }
}
